import React from 'react'
// import NavBar from '../components/Navbar/navbar'
import Layout from '../components/layout'
import cement from '../images/cement.jpg'
import SEO from '../components/seo'

const About = () => (
<Layout >
    <SEO title="About"/>
    <div className="about-page">
        <div id="hero" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)), url(${cement})`}}></div>
    <div className="about-text">
    <h1>Ramirez Concrete</h1>
    <p>We are a family owned and operated company based out of Columbus, Ohio. 
        We pride ourselves on continuously delivering top-quality craftsmanship. 
        <br/><br/>
        Our owner Joe Ramirez has over 25 years of experience and is an expert in the industry. 
        Each Ramirez Concrete project is approached with extreme care. Our clients' satisfaction is our top priority. 
        Whether you need a small repair or have a commercial sized project, we can take care of it all.
        <br/><br/>
        We will turn your idea into a completed project that you will love for years.
    </p>
</div>
</div>
</Layout>
)

export default About